<template>
    <div>
        <div class="table_content">
            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <div class="search">
                <el-form ref="form" :model="form" label-width="80px" :inline="true">
                    <el-form-item label="户室编号:">
                        <el-input v-model="form.name" placeholder="请输入户室编号" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="common()" style="cursor: pointer">搜索</button>
                </el-form>
            </div>
            <el-card>
            <el-table
                    :data="tableData"
                    v-loading="loading"
                    style="width:100%"
                    element-loading-text="拼命加载中"
                    
                    :default-sort="{prop: 'settingTime', order: 'descending'}"
                    :stripe="true"
            >
                <el-table-column
                        prop="villageName"
                        label="小区名称"
                        show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                        fixed
                        prop="towerNum"
                        label="楼幢号"
                >
                </el-table-column>
                <el-table-column
                        prop="unitName"
                        label="单元名称"
                >
                </el-table-column>
                <el-table-column
                        prop="houseNum"
                        label="户室编号"
                >
                </el-table-column>
                <el-table-column
                        prop="coveredarea"
                        label="建筑面积"

                >
                </el-table-column>

                <el-table-column
                        prop="housPurchase"
                        label="购房款"
                        show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                        prop="ownerName"
                        label="业主姓名"
                >

                </el-table-column>
                <el-table-column
                        prop="houseType"
                        label="户室类型"
                        :formatter="formatterHouseType"
                >
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="房屋状态"
                        :formatter="formatterHouseState"
                >
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="120">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="bindOwner(scope.row)" :disabled="scope.row.state == 1||scope.row.state == 2?true:false">绑定</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-row>
                <el-pagination
                        background
                        style="margin-top: 50px"
                        layout="prev, pager, next"
                        :page-size="15"
                        @current-change="handleCurrentChange"
                        :total="totalNum">
                </el-pagination>
                <el-button @click="back" class="btn" size="small">上一步</el-button>
            </el-row>
            </el-card>
        </div>

        <el-dialog
                title="绑定业主"
                :visible.sync="dialogVisibleBindOwner"
                style="vertical-align: middle"
                width="30%"
        >
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                     size="small">
                <el-form-item label="姓名" prop="ownerName">
                    <el-input v-model="ruleForm.ownerName" size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="idCard">
                    <el-input v-model="ruleForm.idCard" size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="ruleForm.phone" size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">立即绑定</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "proHouse",
        components: {
            commonTitle
        },
        data() {
            let validID = (rule, value, callback) => {
                if (value) {
                    let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                    if (!reg.test(value)) {
                        callback(new Error('身份证号码不正确'));
                    } else {
                        callback();
                    }
                }
                callback();

            }

            //前台校验手机号码
            let validPhone = (rule, value, callback) => {
                if (value) {
                    let reg = /^1[3456789]\d{9}$/
                    if (!reg.test(value)) {
                        callback(new Error('手机号码格式不正确'));
                    } else {
                        callback();
                    }
                }
                callback();
            }

            return {
                tableData: [],//表格数据集
                totalNum: 15,
                form: {
                    name: "",
                    region: '',
                },
                loading: false,
                url: this.$Config.base_server,
                dialogVisibleBindOwner: false,
                ruleForm: {
                    ownerName: '',
                    idCard: '',
                    phone: '',
                    houseId: ''
                }, rules: {
                    ownerName: [
                        {required: true, message: '请输入业主姓名', trigger: 'change'}
                    ],
                    idCard: [
                        {required: true, trigger: 'change', message: '请输入身份证号码'},
                        {validator: validID, trigger: 'change'}
                    ],
                    phone: [
                        {required: true, trigger: 'change', message: '请输入手机号码'},
                        {validator: validPhone, trigger: 'change'}
                    ],
                }, data: {
                    access_token: localStorage.getItem("token"),
                    searchKey: 'unitId',
                    searchValue: JSON.parse(localStorage.getItem("unitData")).unitId,
                    limit: 15
                }
            };
        }, mounted() {
            this.common()
        }, methods: {
            common(currentPage = 0) {
                //初始化单元数据
                const that = this;
                that.loading = true;
                that.data.page = currentPage;
                that.data.houseNum = that.form.name;
                $.ajax({
                    url: that.url + "/api-public/opehouse/list",
                    data: that.data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            that.loading = false;
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    },
                    error: function (res) {
                        that.loading = false;
                    }
                });
            },
            back() {
                //返回按钮
                this.$router.push('/proUnit');
            },
            handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
                this.common(val);
            }, deleteRow(index, rows, tableDate) {
                let _this = this;
                let _rows = rows;
                let _index = index;
                //删除相应单元
                this.$confirm('此操作将永久删除该户室信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    _this.loading = true;
                    $.ajax({
                        url: _this.url + "/api-public/opehouse/delete/" + _rows.houseId + "?access_token=" + localStorage.getItem("token"),
                        type: 'delete',
                        success: function (data) {
                            if (data.resp_code == 0) {
                                var that = _this;
                                that.loading = false;
                                tableDate.splice(index, 1);
                                //成功
                                that.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                            }
                        }
                    });
                }).catch(function () {
                });
            }, formatterHouseType(row) {
                //格式化户室类型
                if (row.houseType == 0) {
                    return "商品房";
                }
                if (row.houseType == 1) {
                    return "公租房";
                }
            }, bindOwner(row) {
                //绑定业主
                this.ruleForm.houseId = row.houseId;
                this.dialogVisibleBindOwner = true;
            }, submitForm(formName) {
                const _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {

                        this.$confirm('确定要绑定该用户信息, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            //请求绑定业主的方法
                            $.ajax({
                                url: _this.url + "/api-public/buildownerHouse?access_token=" + localStorage.getItem("token"),
                                data: JSON.stringify(_this.ruleForm),
                                contentType: 'application/json',
                                type: 'post',
                                success: function (result) {
                                    if (result.resp_code == 0) {
                                        //关闭绑定弹框
                                        _this.dialogVisibleBindOwner = false;
                                        _this.$refs['ruleForm'].resetFields();
                                        _this.common();
                                        _this.$message({
                                            type: "success",
                                            message: result.resp_msg
                                        });
                                    }
                                    if (result.resp_code == 1) {
                                        _this.$message({
                                            type:"error",
                                            message:result.resp_msg,
                                            dangerouslyUseHTMLString:true
                                        });

                                        // const h = _this.$createElement;

                                        // _this.$msgbox({
                                        //     title: '',
                                        //     message: h('p', null, [
                                        //         h('i', {style: 'color: red;margin: 0 auto;'}, result.resp_msg)
                                        //     ]),
                                        //     dangerouslyUseHTMLString: true
                                        // })
                                    }
                                }
                            });
                        }).catch(() => {
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }, formatterHouseState(row) {
                //格式化房屋状态
                if (row.state == 0) {
                    return "未绑定";
                } else if (row.state == 1) {
                    return "已绑定";
                } else if (row.state == 2) {
                    return "已激活";
                }
            }
        }
    }
</script>

<style scoped>
    .btn {
        float: right;
        transform: translateY(-34px);
    }
</style>